import {ThemeOptions} from '@material-ui/core/styles';

const theme: ThemeOptions = {
	typography: {
		fontFamily: "'Cantarell', sans-serif",
		h1: {
			color: '#049ad4',
		},
		h2: {
			color: '#049ad4',
		},
		h3: {
			color: '#049ad4',
		},
		h4: {
			color: '#049ad4',
		},
	},
	palette: {
		primary: {
			main: '#049ad4',
		},
		secondary: {
			main: '#016187',
		},
	},
};
export default theme;
