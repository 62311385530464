import * as React from 'react';

import {createTheme, CssBaseline, ThemeProvider} from '@material-ui/core';
import {IWithDarkMode, withDarkMode} from './DarkModeProvider';
import theme from './theme';

type Props = IWithDarkMode;
class ThemeWrapper extends React.Component<Props> {
	public render() {
		const {isDarkMode} = this.props;
		console.log('isDarkMode', isDarkMode ? 'dark' : 'light');
		return (
			<ThemeProvider
				theme={createTheme({
					...theme,
					palette: {
						...theme.palette,
						type: isDarkMode ? 'dark' : 'light',
						background: {
							default: isDarkMode ? '#202020' : '#ffffff',
						},
					},
					overrides: {
						MuiToolbar: {
							root: {
								backgroundColor: '#212529',
							},
						},
						MuiPaper: {
							root: {
								backgroundColor: isDarkMode ? '#212529' : '#efefef',
							},
						},
					},
				})}
			>
				<CssBaseline />
				{this.props.children}
			</ThemeProvider>
		);
	}
}
export default withDarkMode(ThemeWrapper);
